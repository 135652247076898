<template>
  <div class="base-logo-wrapper">
    <img :src="url" alt="trufflepig" />
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
export default {
  props:{
    url: {
      type: [Object,String],
      default: () => {
        return logo
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-logo-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  & img{
    max-width: 100%;
    max-height: 100%;
  }
}
</style>